<template>
  <!-- 协议配置 -->
  <div v-loading="pageLoading" class="agreementConfig">
    <template v-if="channelList.length">
      <el-tabs v-model="channelNo" type="card" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in channelList"
          :key="index"
          :label="item.channelName"
          :name="item.channelNo"
        >
        </el-tab-pane>
      </el-tabs>
      <div class="rule-tabs">
        <div class="rule-tabs-title">
          <div
            v-for="(item, index) in tabsList"
            :key="item.text"
            :class="{ tabs: true, 'tabs-active': index == tabsValue }"
            @click="tabClick(item, index)"
          >
            {{ item.text }}
          </div>
        </div>
        <div class="rule-tabs-content">
          <quill-editor
            ref="myTextEditor"
            v-model="content"
            :options="editorOption"
            style="height: calc(100% - 120px)"
          ></quill-editor>
          <el-button class="save" @click="saveFun">保存</el-button>
        </div>
      </div>
    </template>
    <div
      v-else-if="emptyFlag"
      style="width: 100%; font-size: 18px; text-align: center"
    >
      <img src="~@/assets/images/home/empty.png" alt />
      <div>暂无权限，请联系管理员</div>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import {
  agreementConfigAPI,
  agreementConfigUpdateAPI,
  getCurrentUserChannelInfoAPI,
} from "@/api/lib/api.js";
export default {
  data() {
    return {
      pageLoading: true,
      emptyFlag: false,
      channelNo: null,
      channelList: [],
      tabsList: [
        { type: 1, code: 1, text: "城际专车-购票须知" },
        { type: 1, code: 0, text: "城际专车-用车协议" },
        { type: 2, code: 1, text: "汽车票-购票须知" },
        { type: 4, code: 1, text: "包车-包车须知" },
        { type: 5, code: 3, text: "租车-租车服务合同" },
        { type: 5, code: 4, text: "租车-预订服务协议" },
        { type: 6, code: 3, text: "公交出行-用户服务协议" },
        { type: 0, code: 1, text: "用户注册协议" },
        { type: 0, code: 2, text: "隐私政策" },
      ],
      tinymceHtml: "",
      content: "",
      editorOption: {
        placeholder: "编辑内容",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            [], // 链接、图片、视频
          ],
        },
      },
      tabsValue: 0,
      Data: "",
    };
  },
  components: {
    quillEditor,
  },
  mounted() {
    this.getQueryChannelList();
  },
  watch: {
    tabsValue: {
      handler() {
        this.renderDataAPI();
      },
    },
  },
  methods: {
    //点击渠道配置tab项
    handleClick() {
      this.renderDataAPI();
    },
    //获取渠道数据
    getQueryChannelList() {
      getCurrentUserChannelInfoAPI()
        .then((res) => {
          if (res.code == "SUCCESS") {
            this.channelList = res.data;
            this.channelNo = res.data[0]?.channelNo;
            if (!res.data.length) {
              this.$message.error("暂无权限，请联系管理员");
              this.emptyFlag = true;
              this.pageLoading = false;
              return;
            }
            this.pageLoading = false;
            this.renderDataAPI();
          }
        })
        .catch(() => {
          this.pageLoading = false;
        });
    },
    // 点击tab标签
    tabClick(item, index) {
      this.tabsValue = index;
    },
    saveFun() {
      this.saveDataAPI(this.content, this.tabsValue);
    },
    // 修改协议配置
    saveDataAPI(val, num) {
      if (!val) {
        this.$message.error("请输入内容！");
        return;
      }
      let obj = this.tabsList[num];
      obj.channelNo = this.channelNo;
      const sign = this.Data.find(
        (i) => i.type == obj.type && i.code == obj.code
      );
      obj.id = sign ? sign.id : null;
      obj.pact = val;
      obj.title = this.tabsList[num].text;
      agreementConfigUpdateAPI(obj).then((res) => {
        if (res.code === "SUCCESS" && res.data) {
          this.$message.success("保存成功");
          this.renderDataAPI();
        }
      });
    },
    // 获取协议配置
    renderDataAPI() {
      agreementConfigAPI({ channelNo: this.channelNo }).then((res) => {
        if (res.code == "SUCCESS") {
          this.Data = res.data;
          let obj = this.tabsList[this.tabsValue];
          this.content = res.data.find(
            (item) => item.type == obj.type && item.code == obj.code
          )?.pact;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.agreementConfig {
  height: 100%;
  background-color: #ffffff;
  .save {
    margin-top: 80px;
    background: #336fee;
    color: #ffffff;
  }
  .rule-tabs {
    width: 100%;
    // border: 1px solid #dcdfe6;
    height: 100%;
    display: flex;
    .rule-tabs-title {
      width: 18%;
      height: 40px;
      // background: #f5f7fa;
      // border-bottom: 1px solid #dcdfe6;
      .tabs {
        width: 100%;
        display: inline-block;
        height: 100%;
        padding: 0 20px;
        cursor: pointer;
        // color: #909399;
        font-size: 14px;
        line-height: 40px;
        margin-left: -1px;
        // border-left: 1px solid transparent;
        // border-right: 1px solid transparent;
        box-sizing: border-box;
      }
      .tabs-active {
        // border-bottom: 1px solid #ffffff;
        color: #409eff;
        background-color: #fff;
        // border-right: 1px solid #dcdfe6;
        // border-left: 1px solid #dcdfe6;
      }
    }
    .rule-tabs-content {
      width: 82%;
      padding: 15px;
      background: #ffffff;
      height: calc(100% - 72px);
      .tabs-add {
        background: #336fee;
        color: #ffffff;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
