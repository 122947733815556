var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading",
        },
      ],
      staticClass: "agreementConfig",
    },
    [
      _vm.channelList.length
        ? [
            _c(
              "el-tabs",
              {
                attrs: { type: "card" },
                on: { "tab-click": _vm.handleClick },
                model: {
                  value: _vm.channelNo,
                  callback: function ($$v) {
                    _vm.channelNo = $$v
                  },
                  expression: "channelNo",
                },
              },
              _vm._l(_vm.channelList, function (item, index) {
                return _c("el-tab-pane", {
                  key: index,
                  attrs: { label: item.channelName, name: item.channelNo },
                })
              }),
              1
            ),
            _c("div", { staticClass: "rule-tabs" }, [
              _c(
                "div",
                { staticClass: "rule-tabs-title" },
                _vm._l(_vm.tabsList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.text,
                      class: {
                        tabs: true,
                        "tabs-active": index == _vm.tabsValue,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.tabClick(item, index)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.text) + " ")]
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "rule-tabs-content" },
                [
                  _c("quill-editor", {
                    ref: "myTextEditor",
                    staticStyle: { height: "calc(100% - 120px)" },
                    attrs: { options: _vm.editorOption },
                    model: {
                      value: _vm.content,
                      callback: function ($$v) {
                        _vm.content = $$v
                      },
                      expression: "content",
                    },
                  }),
                  _c(
                    "el-button",
                    { staticClass: "save", on: { click: _vm.saveFun } },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ]),
          ]
        : _vm.emptyFlag
        ? _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "font-size": "18px",
                "text-align": "center",
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/home/empty.png"),
                  alt: "",
                },
              }),
              _c("div", [_vm._v("暂无权限，请联系管理员")]),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }